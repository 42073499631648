export const columns = [
  {
    id: 1,
    accessorKey: "#",
    header: "#",
  },
  {
    id: 2,
    accessorKey: "title",
    header: "Title",
  },
  {
    id: 3,
    accessorKey: "complete",
    header: "Complete",
  },
];
